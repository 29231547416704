import * as React from 'react'
import { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useNodeStatsQuery } from '../../generated/graphql'
import {
  Button,
  DataTable,
  IconButton,
  TouchableRipple,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { TitledTable } from '../../components/TitledTable/TitledTable'
import { MultiLineCell } from '../../components/TitledTable/MultiLineCell'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import SingleCell from '../../components/TitledTable/SingleCell'
import { CenteredAnimatedActivity } from '../../components/TitledTable/CenteredAnimatedActivity'
import { IconClusterSettings } from '../../utils/meldd-icons'
import { useAuthContext } from '../../auth/auth-context'
import { useTheme } from '../../hooks/use-theme'
import * as cluster from 'cluster'
import { useCurrentNode } from '../../contexts/CurrentNodeProvider'

export function NodeStatsScreen({
  route,
  navigation,
}: StackScreenProps<AppNavigatorParams, 'NodeStats'>) {
  const { node } = route.params
  const { loading, error, data, refetch } = useNodeStatsQuery({
    variables: { input: { nodeId: node.id, usePublicCoRank: !node.isPrivate } },
    fetchPolicy: 'no-cache',
  })
  const { t } = useTranslation('nodeStats')
  const { isAdmin } = useAuthContext()
  const globalStyles = useGlobalStyles()
  const theme = useTheme()

  ///////////////////////////////////////////////////////////
  //                      Perspectives
  ///////////////////////////////////////////////////////////
  const perspectives = useMemo(() => {
    if (loading && !data?.nodeStats) return <SingleCell message={''} />
    if (!loading && (!data?.nodeStats || !data.nodeStats.perspectives.length))
      return <SingleCell key="0" message={t('No Perspectives')} />
    if (error)
      return <SingleCell key="0" message={t('Failed to load perspectives')} />

    return (data?.nodeStats.perspectives || []).map((perspective) => {
      const onPress = () =>
        navigation.push('Perspective', {
          articleId: perspective.id,
          clusterId: perspective.cluster.id,
          nodeId: data!.nodeStats.nodeId,
        })
      return (
        <Animated.View key={perspective.id} entering={FadeIn} exiting={FadeOut}>
          <TouchableRipple onPress={onPress}>
            <DataTable.Row>
              <MultiLineCell
                title={perspective.title}
                description={perspective.cluster.topic}
              />
              <DataTable.Cell numeric>{perspective.voteCount}</DataTable.Cell>
            </DataTable.Row>
          </TouchableRipple>
        </Animated.View>
      )
    })
  }, [data?.nodeStats, loading, error])

  ///////////////////////////////////////////////////////////
  //                      Contributions
  ///////////////////////////////////////////////////////////
  const contributions = useMemo(() => {
    if (loading && !data?.nodeStats) return <SingleCell message={''} />
    if (!loading && (!data?.nodeStats || !data.nodeStats.contributions.length))
      return <SingleCell key="0" message={t('No Contributions')} />
    if (error)
      return <SingleCell key="0" message={t('Failed to load contributions')} />
    return (data?.nodeStats.contributions || []).map((contribution) => {
      const onPress = () =>
        navigation.push('ListContributionsProfile', {
          contributions: [contribution],
          type: t(''),
        })
      return (
        <Animated.View
          key={contribution.id}
          entering={FadeIn}
          exiting={FadeOut}
        >
          <TouchableRipple onPress={onPress}>
            <DataTable.Row>
              <MultiLineCell
                title={contribution.originalText}
                description={contribution.contributionType}
              />
              <DataTable.Cell numeric>{contribution.bumpScore}</DataTable.Cell>
            </DataTable.Row>
          </TouchableRipple>
        </Animated.View>
      )
    })
  }, [data?.nodeStats, loading, error])

  ///////////////////////////////////////////////////////////
  //                      User Rankings
  ///////////////////////////////////////////////////////////
  const users = useMemo(() => {
    if (loading && !data?.nodeStats) return <SingleCell message={''} />
    if (!loading && (!data?.nodeStats || !data.nodeStats.users.length))
      return <SingleCell key="0" message={t('No Contributions')} />
    if (error) return <SingleCell key="0" message={t('Failed to load users')} />
    return (data?.nodeStats.users || []).map((user, ix) => {
      const onPress = () => navigation.push('Profile', { userId: user.id })

      return (
        <Animated.View key={user.id} entering={FadeIn} exiting={FadeOut}>
          <TouchableRipple onPress={onPress}>
            <DataTable.Row>
              <DataTable.Cell>{user.username}</DataTable.Cell>
              <DataTable.Cell numeric>{ix + 1}</DataTable.Cell>
            </DataTable.Row>
          </TouchableRipple>
        </Animated.View>
      )
    })
  }, [data?.nodeStats, loading, error])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        title: {
          flex: 1,
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'center',
        },
        mainTitle: {
          textAlign: 'center',
          fontSize: 22,
          fontWeight: '700',
        },
        button: {
          marginTop: -theme.spacing(1.5),
          marginRight: -theme.spacing(1),
          justifyContent: 'center',
        },
      }),
    [theme]
  )

  return (
    <ScreenWrapper style={[globalStyles.pageContainer]}>
      <View style={{ marginBottom: 10 }}>
        <View style={styles.title}>
          <Text style={styles.mainTitle}>{node.name}</Text>
          {isAdmin && (
            <IconButton
              style={styles.button}
              onPress={() => navigation.navigate('NodeSettings', { node })}
              icon={IconClusterSettings}
            />
          )}
        </View>

        <Text style={{ textAlign: 'center', fontSize: 18, fontWeight: '500' }}>
          {node.description}
        </Text>
      </View>
      <TitledTable
        title={t('Top Perspectives')}
        header={
          <>
            <DataTable.Title>{t('Title')}</DataTable.Title>
            <DataTable.Title numeric>{t('Votes')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          {perspectives}
        </View>
      </TitledTable>
      <View style={globalStyles.verticalSpacer} />
      <View style={globalStyles.verticalSpacer} />
      <TitledTable
        title={t('Top Contributions')}
        header={
          <>
            <DataTable.Title>{t('Contributions Type')}</DataTable.Title>
            <DataTable.Title numeric>{t('Net Bumps')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          {contributions}
        </View>
      </TitledTable>
      <View style={globalStyles.verticalSpacer} />
      <View style={globalStyles.verticalSpacer} />
      <TitledTable
        title={t('CoRank')}
        header={
          <>
            <DataTable.Title>{t('Username')}</DataTable.Title>
            <DataTable.Title numeric>{t('Rank')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          {users}
        </View>
      </TitledTable>
    </ScreenWrapper>
  )
}

export default NodeStatsScreen
