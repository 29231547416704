import { StyleProp, View, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useMemo } from 'react'
import 'react-native-get-random-values'
import {
  ArticlesForUserQuery,
  useArticlesForUserQuery,
} from '../../../generated/graphql'
import { Unpacked } from '../../../utils/types'
import { AppNavigation } from '../../../navigation/types'
import { useTranslation } from 'react-i18next'
import SingleCell from '../../../components/TitledTable/SingleCell'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { DataTable, TouchableRipple } from 'react-native-paper'
import { TitledTable } from '../../../components/TitledTable/TitledTable'
import { CenteredAnimatedActivity } from '../../../components/TitledTable/CenteredAnimatedActivity'
import { orderBy } from 'lodash'
import { useAuthContext } from '../../../auth/auth-context'

interface Props {
  userId: string
  style?: StyleProp<ViewStyle>
}

type Article = Unpacked<ArticlesForUserQuery['articlesForUser']>

function UserPerspectives(props: Props) {
  const { userId } = props
  const navigation = useNavigation<AppNavigation>()
  const { authAction } = useAuthContext()
  const { t } = useTranslation('profile')
  const { loading, error, data } = useArticlesForUserQuery({
    variables: { userId: userId },
  })

  const perspectives = useMemo(() => {
    if (loading && !data?.articlesForUser) return <SingleCell message={''} />
    if (!loading && (!data?.articlesForUser || !data.articlesForUser.length))
      return <SingleCell key="0" message={t('No Perspectives')} />
    if (error)
      return <SingleCell key="0" message={t('Failed to load perspectives')} />

    const sorted = orderBy(
      data?.articlesForUser || [],
      ['contributionCount'],
      ['desc']
    ) as Article[]
    if (sorted.length > 3) {
      sorted.length = 3
    }
    return sorted.map((perspective) => {
      const onPress = () => {
        authAction(navigation, () => navigation.navigate('Perspective', {
          articleId: perspective.id,
          clusterId: perspective.clusterId,
          nodeId: perspective.cluster.nodeId,
        }))
      }
      return (
        <Animated.View key={perspective.id} entering={FadeIn} exiting={FadeOut}>
          <TouchableRipple onPress={onPress}>
            <DataTable.Row>
              <DataTable.Cell>{perspective.title}</DataTable.Cell>
              <DataTable.Cell numeric>
                {perspective.contributionCount}
              </DataTable.Cell>
            </DataTable.Row>
          </TouchableRipple>
        </Animated.View>
      )
    })
  }, [data?.articlesForUser, loading, error])

  return (
    <View style={props.style}>
      <TitledTable
        header={
          <>
            <DataTable.Title>{t('Perspective')}</DataTable.Title>
            <DataTable.Title numeric>{t('Contributions')}</DataTable.Title>
          </>
        }
      >
        <View style={{ position: 'relative' }}>
          <CenteredAnimatedActivity show={loading} />
          {perspectives}
        </View>
      </TitledTable>
    </View>
  )
}

export default UserPerspectives
