import * as React from 'react'
import { View, StyleSheet, ListRenderItemInfo } from 'react-native'
import { Text } from 'react-native-paper'
import { useTheme } from '../../../hooks/use-theme'
import { useEffect, useMemo, useState } from 'react'
import {
  ContributionStatus,
  ContributionType,
} from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { Contribution } from '../types'
import { ContributionCard } from './ContributionCard'
import { ScreenWrapper } from '../../../components/ScreenWrapper'
import ContributionStatusSelector from './ContributionStatusSelector'
import ContributionTypeSelector from './ContributionTypeSelector'
import { BumpDetailsProvider } from './BumpDetailsProvider'
import { ConfirmCensureProvider } from './ConfirmCensureProvider'
import { FlatList } from 'react-native'
import { BumpJustificationProvider } from './BumpJustificationProvider'

interface NonEditableContributionListProps {
  contributions: Contribution[]
  initialTypeFilter?: ContributionType
  initialStatusFilter?: ContributionStatus | ContributionStatus[]
  loading?: boolean
  hideFilters?: boolean
}

export function NonEditableContributionList(
  props: NonEditableContributionListProps
) {
  const { contributions } = props
  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const [typeFilter, setTypeFilter] = useState<ContributionType | undefined>(
    undefined
  )
  const [statusFilter, setStatusFilter] = useState<
    ContributionStatus | undefined | ContributionStatus[]
  >(undefined)

  const visibleContributions = useMemo(
    () =>
      contributions
        .filter((c) => !typeFilter || c.contributionType == typeFilter)
        .filter((c) => {
          if (!statusFilter) return true
          if (Array.isArray(statusFilter))
            return statusFilter.includes(c.status)
          return c.status == statusFilter
        }),
    [contributions, typeFilter, statusFilter]
  )

  useEffect(() => {
    setTypeFilter(props.initialTypeFilter)
  }, [props.initialTypeFilter])
  useEffect(() => {
    setStatusFilter(props.initialStatusFilter)
  }, [props.initialStatusFilter])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        empty: {
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        },
        list: {
          marginTop: theme.spacing(5),
          flex: 1,
        },
        menuRow: {
          marginBottom: theme.spacing(3),
          marginHorizontal: theme.spacing(5),
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        },
        sortBy: {},
        typeMenu: {
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          columnGap: theme.spacing(2),
        },
      }),
    [theme]
  )

  const renderItem = ({ item }: ListRenderItemInfo<Contribution>) => {
    return (
      <ContributionCard
        style={{ marginBottom: theme.spacing(4) }}
        key={item.id}
        showStatus={true}
        showEdit={false}
        contribution={item}
      />
    )
  }

  return (
    <ScreenWrapper
      withScrollView={false}
      style={{
        // paddingTop: theme.spacing(5),
        // paddingLeft: theme.spacing(5),
        // paddingRight: theme.spacing(5),
        paddingBottom: 0,
      }}
    >
      <View style={styles.list}>
        {!props.hideFilters && (
          <View style={styles.menuRow}>
            <View style={styles.typeMenu}>
              <Text>{t('Status')}</Text>
              <ContributionStatusSelector
                onSelect={setStatusFilter}
                selected={statusFilter}
              />
            </View>
            <View style={styles.typeMenu}>
              <Text>{t('Type')}</Text>
              <ContributionTypeSelector
                onSelect={setTypeFilter}
                selected={typeFilter}
              />
            </View>
          </View>
        )}
        {visibleContributions.length > 0 ? (
          <BumpDetailsProvider>
            <BumpJustificationProvider>
              <ConfirmCensureProvider>
                <FlatList
                  style={{
                    paddingHorizontal: theme.spacing(5),
                  }}
                  data={visibleContributions}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.id}
                  ListFooterComponent={<View style={{ height: 200 }}></View>}
                />
              </ConfirmCensureProvider>
            </BumpJustificationProvider>
          </BumpDetailsProvider>
        ) : (
          <View style={styles.empty}>
            <Text
              variant={'titleMedium'}
              style={{ color: theme.palettes.neutral[70]! }}
            >
              {t('No contributions')}
            </Text>
          </View>
        )}
      </View>
    </ScreenWrapper>
  )
}
