import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dimensions, Keyboard, Pressable, View } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import Toast from 'react-native-root-toast'
import {
  CreateClusterMemberInput,
  SearchUserQuery,
  UpdateClusterMemberInput,
  useCreateClusterMemberMutation,
  useGetMembersByClusterIdQuery,
  useRemoveClusterMemberMutation,
  useSearchUserLazyQuery,
  useUpdateClusterMemberMutation,
} from '../../../generated/graphql'
import { sortBy } from 'lodash'
import { Unpacked } from '../../../utils/types'
import { useTranslation } from 'react-i18next'
import { TitledTable } from '../../../components/TitledTable/TitledTable'
import {
  Checkbox,
  DataTable,
  Icon,
  IconButton,
  Menu,
  Searchbar,
  Text,
} from 'react-native-paper'
import SingleCell from '../../../components/TitledTable/SingleCell'
import { IconDeleteMember } from '../../../utils/meldd-icons'
import { MessageType } from '../../../utils/message-type'
import { useTheme } from '../../../hooks/use-theme'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import Row from '../../../components/containers/Row'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'

interface Props {
  clusterId: string
}

type ListUser = Unpacked<SearchUserQuery['usersBySearchUsername']>
export const ClusterMemberList = (props: Props) => {
  const { clusterId } = props
  const { t } = useTranslation('clusters')
  const theme = useTheme()
  const [search, setSearch] = useState<string>('')
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const searchRef = useRef<any>()
  const [searchResult, setSearchResult] = useState<ListUser[]>([])
  const styles = useStyle()
  const {
    loading,
    data: clusterMembersData,
    refetch,
    error,
  } = useGetMembersByClusterIdQuery({ variables: { clusterId } })
  const clusterMembers = useMemo(
    () =>
      sortBy(clusterMembersData?.clusterMembersByClusterId || [], [
        'user.username',
      ]),
    [clusterMembersData]
  )
  const [searchByUsername, { loading: loadingSearch, data: dataSearch }] =
    useSearchUserLazyQuery()
  useEffect(() => {
    refetch({ clusterId })
  }, [])
  const [doAddMember] = useCreateClusterMemberMutation({
    onCompleted: () => refetch(),
  })
  const [doRemoveMember] = useRemoveClusterMemberMutation({
    onCompleted: () => refetch(),
  })
  const [doUpdateMember] = useUpdateClusterMemberMutation()

  const members = useMemo(() => {
    if (loading && !clusterMembersData?.clusterMembersByClusterId)
      return <SingleCell message={''} />
    if (error)
      return <SingleCell key="0" message={t('Failed to load members')} />
    return clusterMembers.map((member, ix) => {
      return (
        <Animated.View key={member.id} entering={FadeIn} exiting={FadeOut}>
          <DataTable.Row style={styles.row}>
            <View style={styles.wrapper}>
              <View style={styles.username}>
                <Text numberOfLines={1} ellipsizeMode={'tail'}>
                  {member.user.username!}
                </Text>
                <View style={styles.moderator}>
                  <Checkbox
                    onPress={() => updateMember(member.id, !member.isModerator)}
                    status={member.isModerator ? 'checked' : 'unchecked'}
                  />
                  <Pressable
                    style={{ maxHeight: 20, position: 'relative' }}
                    onPress={() => updateMember(member.id, !member.isModerator)}
                  >
                    <Text
                      style={{
                        color: member.isModerator
                          ? theme.colors.text
                          : theme.colors.onSurfaceDisabled,
                      }}
                      variant={'labelSmall'}
                    >
                      {t('Moderator')}
                    </Text>
                  </Pressable>
                </View>
              </View>
              <IconButton
                onPress={() => deleteMember(member.id)}
                icon={IconDeleteMember}
              />
            </View>
          </DataTable.Row>
        </Animated.View>
      )
    })
  }, [clusterMembers, clusterMembersData, loading, error])

  const clearInput = useCallback(() => {
    Keyboard.dismiss()
    setSearchResult([])
    setShowSearch(false)
    setSearch('')
  }, [])

  const addNewMember = useCallback(
    async (userId: string) => {
      searchRef.current?.blur()
      const input: CreateClusterMemberInput = {
        userId,
        clusterId,
        isModerator: false,
      }
      try {
        await doAddMember({ variables: { input } })
      } catch (err) {
        Toast.show(t('Failed to add a member'), MessageType.error)
      }
      clearInput()
    },
    [clearInput]
  )

  const updateMember = useCallback(async (id: string, isModerator: boolean) => {
    const input: UpdateClusterMemberInput = { id, isModerator }
    try {
      await doUpdateMember({ variables: { input } })
    } catch (err) {
      Toast.show(t('Failed to update a member'), MessageType.error)
    }
  }, [])

  const deleteMember = useCallback(async (id: string) => {
    try {
      await doRemoveMember({ variables: { id } })
    } catch (err) {
      Toast.show(t('Failed to delete a member'), MessageType.error)
    }
  }, [])

  const searchMembers = useMemo(() => {
    if (!searchResult) {
      return <></>
    }
    return (
      <>
        {searchResult.map((item, index) => {
          const isMember = clusterMembers.find(
            (member) => member.user.username === item.username
          )
          return (
            <Menu.Item
              disabled={isMember !== undefined}
              onPress={() => addNewMember(item.id)}
              key={index}
              title={item.username}
            />
          )
        })}
      </>
    )
  }, [clusterMembers, searchResult])

  async function handleSearch(input: string) {
    setSearch(input)
    if (input.trim().length < 1) return
    const query = await searchByUsername({
      variables: { username: input.trim().toLowerCase() },
      fetchPolicy: 'network-only',
    })
    if (query.data) {
      const result: ListUser[] = query.data.usersBySearchUsername
      setSearchResult(result)
    }
  }
  const windowWidth = Dimensions.get('window').width
  return (
    <TitledTable
      header={
        <Row justifyContent="space-between" flex={1}>
          <DataTable.Title>{t('Private Cluster Members')}</DataTable.Title>
          <Menu
            visible={showSearch}
            style={{
              flex: 1,
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(4),
              width: windowWidth - 2 * theme.spacing(4),
            }}
            anchorPosition={'bottom'}
            onDismiss={() => setShowSearch(false)}
            anchor={
              <TouchableIconWithToolTip
                icon={<Icon source="plus-circle-outline" size={20} />}
                tooltip={t('add-member')}
                onPress={() => setShowSearch(true)}
              />
            }
          >
            <Searchbar
              placeholder={t('search')}
              onChangeText={handleSearch}
              value={search}
            />
            {searchMembers}
          </Menu>
        </Row>
      }
    >
      <View style={{ position: 'relative' }}>{members}</View>
    </TitledTable>
  )
}

export default ClusterMemberList

const useStyle = createStyle(() => ({
  row: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: 'row',
  },
  wrapper: {
    flexGrow: 1,
    margin: 8,
    marginLeft: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moderator: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))
