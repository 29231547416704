import React, { useState, useEffect, useCallback } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import HTML from 'react-native-render-html'
import { Change, diffWords } from 'diff'
import { Contribution } from '../types'
import { useTranslation } from 'react-i18next'

const validateHTML = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  if (doc.body.innerHTML === html) {
    return ''
  }

  const differences = diffWords(html, doc.body.innerHTML)

  const result = differences.reduce((text: string, part: Change) => {
    const escapedValue = part.value
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')

    const value = part.added
      ? `<span style="color: #006400; font-size: 18px; font-weight: bold;">${escapedValue}</span>`
      : part.removed
      ? `<span style="color: #8B0000; font-size: 18px; font-weight: bold;">${escapedValue}</span>`
      : `<span style="color: #696969; font-size: 18px; font-weight: bold;">${escapedValue}</span>`
    return text + value
  }, '')

  return result
}

/**
 * Get a slice of the context around the contribution
 */
const getContextSlice = ({
  allText,
  selectedText,
  paddingChars,
  startFrom,
}: {
  allText: string
  selectedText: string
  paddingChars: number
  startFrom: number
}) => {
  const replacementIndex = allText.indexOf(selectedText, startFrom)
  const startIndex = Math.max(0, replacementIndex - paddingChars)
  const endIndex = Math.min(
    allText.length,
    replacementIndex + selectedText.length + paddingChars
  )
  const contextSlice = allText.slice(startIndex, endIndex)
  return contextSlice
}

export const useValidationMessage = (
  inputText: string,
  contribution: Contribution,
  context: string
) => {
  const [isVisible, setIsVisible] = useState(false)
  const [validationResult, setValidationResult] = useState('')

  const validateMessage = useCallback(() => {
    const beforeContribution = context.slice(0, contribution.begin)
    const afterContribution = context.slice(
      contribution.begin + contribution.originalText.length
    )
    const contextAfterContribution =
      beforeContribution + inputText + afterContribution

    const contextSlice = getContextSlice({
      allText: contextAfterContribution,
      selectedText: inputText,
      startFrom: contribution.begin,
      paddingChars: 50,
    })
    const result = validateHTML(contextSlice)

    setValidationResult(result)
  }, [inputText, contribution, context])

  useEffect(() => {
    validateMessage()
  }, [validateMessage])

  return validationResult
}
