
import { StyleSheet } from 'react-native'
import { Dialog, useTheme, Button, Text, Portal } from 'react-native-paper'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface InternalProps {
  children?: JSX.Element | JSX.Element[]
  label?: string;
  onContinue: () => Promise<void | boolean>
  acceptLabel?: string,
  cancelLabel?: string
}

export interface ConfirmDialogueRef {
  close: () => void
  open: () => void;
}
export const ConfirmDialogue = forwardRef<ConfirmDialogueRef, InternalProps>(({ children, label, onContinue, acceptLabel, cancelLabel }: InternalProps, ref) => {
  const { t } = useTranslation('common')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const theme = useTheme()

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  const continueWrapper = useCallback(async () => {
    setLoading(true)

    const res = await onContinue()
    setLoading(false)
    if (res === false) {
      return
    }

    setVisible(false)
  }, [onContinue])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.surfaceVariant,
        },
        title: {
          fontWeight: 'bold',
        },
        heading: {
          color: theme.colors.onSurfaceVariant,
        },
        button: {
          // paddingHorizontal: theme.spacing(2),
        },
      }),
    [theme]
  )

  return (
    <Portal>
      <Dialog
        style={styles.dialog}
        visible={!!visible}
        onDismiss={() => setVisible(false)}>
        <Dialog.Title>
          <Text
            variant="bodyMedium"
            style={styles.heading}
          >
            {label || t('Are you sure?')}
          </Text>
        </Dialog.Title>
        <Dialog.Content>
          {children}
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onPress={() => setVisible(false)}>
            {cancelLabel || t('Cancel')}
          </Button>
          <Button
            mode={'contained'}
            style={styles.button}
            loading={loading}
            onPress={() => continueWrapper()}>
            {acceptLabel || t('Accept')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
})
