import React, { Suspense, useContext, useEffect, useMemo } from 'react'
import 'expo-dev-client'
// Needed for web, since GestureDetector is using it
import 'setimmediate'
import './socket/socket-client' // Initialize the socket object first
import AppNavigator, { AppNavigatorScreens } from './navigation/appNavigator'
import {
  LinkingOptions,
  NavigationContainer,
  getStateFromPath,
} from '@react-navigation/native'
import { AuthContext, AuthContextProvider } from './auth/auth-context'
import { RootSiblingParent } from 'react-native-root-siblings'
import { useFonts } from 'expo-font'
import { AuthClientProvider } from './auth/auth-client-provider'
import { CurrentNodeProvider } from './contexts/CurrentNodeProvider'
import FeatureProvider from './contexts/FeatureProvider'
import 'intl-pluralrules'
import './i18n'
import { Platform, Text, View } from 'react-native'
import { PushTokenProvider } from './contexts/PushTokenProvider'
import * as Notifications from 'expo-notifications'
import * as TaskManager from 'expo-task-manager'
import * as Linking from 'expo-linking'
import { TaskManagerTaskBody } from 'expo-task-manager'
import { PushNotificationTrigger } from 'expo-notifications'
import { useTheme } from './hooks/use-theme'
import { Provider as PaperProvider } from 'react-native-paper'
import { GlobalStylesProvider } from './contexts/GlobalStylesProvider'
import { VoteDetailsProvider } from './components/vote/VoteDetailsProvider'
import { HeaderButtonProvider } from './contexts/HeaderButtonProvider'
import * as Updates from 'expo-updates'
import {
  ModalDialogProvider,
  useModalDialog,
} from './components/ModalDialogProvider'
import { Env } from './env'
import { useTranslation } from 'react-i18next'
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler'
import * as Sentry from '@sentry/react-native'
import NotificationProvider from './contexts/NotificationProvider'

Sentry.init({
  environment: Env.BUILD_TYPE,
  dsn: Env.SENTRY_SDN || '',
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
})

if (Platform.OS == 'web') {
  enableExperimentalWebImplementation()
}

if (Platform.OS != 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    }),
  })

  const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK'
  TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, (body) => {
    const { data, error, executionInfo } =
      body as TaskManagerTaskBody<PushNotificationTrigger>
    console.log('Received a notification in the background!', data)
    if ('notification' in data) {
      // @ts-ignore
      const {
        notification: {
          data: { body },
        },
      } = data
      const parsed = JSON.parse(body)
      if ('pendingCount' in parsed) {
        console.log('Setting badge count to', parsed.pendingCount)
        Notifications.setBadgeCountAsync(parsed.pendingCount)
      }
    } else if ('payload' in data) {
    } else {
    }
    // Do something with the notification data
  })

  Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK).then()
}

function getUrlFromNotification(notification?: Notifications.Notification) {
  console.log(JSON.stringify(notification, null, 4))

  // TODO: Sorry for the `?` chain, this seems conflicting with google login redirect
  const url =
    notification?.request?.content?.data?.type === 'contribution-pending'
      ? `decisions`
      : null
  return url ? Linking.createURL(url) : null
}

function Navigation() {
  const { token, loading } = useContext(AuthContext)
  const linking: LinkingOptions<any> = useMemo(() => {
    return {
      prefixes: [Linking.createURL('')],
      config: {
        initialRouteName: 'ClusterList',
        screens: AppNavigatorScreens,
      },
      getStateFromPath: (path, options) => {
        // This fires when we access directly the URL
        const stateFromPath = getStateFromPath(path, options)
        if (!stateFromPath) {
          return stateFromPath
        }
        // We add an external param to know this is a direct link reload
        const result = {
          ...stateFromPath,
          routes: stateFromPath.routes.map((r) => ({
            ...r,
            params: { ...r.params, external: true },
          })),
        }

        return result
      },
      async getInitialURL() {
        // First, you may want to do the default deep link handling
        // Check if app was opened from a deep link
        const url = await Linking.getInitialURL()
        console.log('getInitialURL', url)
        if (url != null) {
          return url
        }
        // Handle URL from expo push notifications
        const response = await Notifications.getLastNotificationResponseAsync()
        return getUrlFromNotification(response?.notification)
      },
      subscribe(listener) {
        // Listen to incoming links from deep linking
        const s1 = Linking.addEventListener('url', (event: { url: string }) => {
          console.log('URL event', event)
          listener(event.url)
        })

        // Listen to expo push notifications
        const subscription =
          Notifications.addNotificationResponseReceivedListener(
            (response: Notifications.NotificationResponse) => {
              // simple mapping of type to url for now
              console.log('NotificationResponseReceived in Linking', response)
              const url = getUrlFromNotification(response.notification)
              if (url) {
                listener(url)
              }
            }
          )

        return () => {
          // Clean up the event listeners
          s1.remove()
          subscription.remove()
        }
      },
    }
  }, [])

  const theme = useTheme()
  const { setDialog } = useModalDialog()
  const { t } = useTranslation('common')
  useEffect(() => {
    if (Env.IS_DEVELOP) {
      return
    }

    async function onFetchUpdateAsync() {
      try {
        const update = await Updates.checkForUpdateAsync()

        if (update.isAvailable) {
          await Updates.fetchUpdateAsync()
          setDialog({
            title: t('Update available'),
            body: <Text>{t('New version available')}</Text>,
            primaryLabel: t('Update'),
            primaryAction: async () => {
              setDialog(null)
              await Updates.reloadAsync()
            },
          })
        }
      } catch (error) {
        console.log(`Error fetching latest Expo update: ${error}`)
      }
    }

    onFetchUpdateAsync().then()
  }, [])

  if (loading) {
    return null
  }
  return (
    <NavigationContainer linking={linking} theme={theme}>
      <PushTokenProvider>
        <CurrentNodeProvider>
          <VoteDetailsProvider>
            <HeaderButtonProvider>
              <NotificationProvider>
                <AppNavigator />
              </NotificationProvider>
            </HeaderButtonProvider>
          </VoteDetailsProvider>
        </CurrentNodeProvider>
      </PushTokenProvider>
    </NavigationContainer>
  )
}

function ThemedApp() {
  const theme = useTheme()

  return (
    <PaperProvider theme={theme}>
      <GlobalStylesProvider>
        <RootSiblingParent>
          <AuthContextProvider>
            <AuthClientProvider>
              <ModalDialogProvider>
                <Navigation />
              </ModalDialogProvider>
            </AuthClientProvider>
          </AuthContextProvider>
        </RootSiblingParent>
      </GlobalStylesProvider>
    </PaperProvider>
  )
}

const App = () => {
  useFonts({
    Nucleo: require('./assets/thumbup/fonts/Nucleo.ttf'),
  })
  return (
    <>
      <Suspense fallback={<View></View>}>
        <FeatureProvider>
          <ThemedApp />
        </FeatureProvider>
      </Suspense>
    </>
  )
}

export default Sentry.wrap(App)
