import { ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import { useEffect, useState } from 'react'
import MelddButton from '../components/MelddButton'
import Toast from 'react-native-root-toast'
import Checkbox from 'expo-checkbox'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../navigation/types'
import {
  UpdateArticleInput,
  useDeleteArticleMutation,
  useUpdateArticleMutation,
} from '../generated/graphql'
import { MessageType } from '../utils/message-type'
import { useTranslation } from 'react-i18next'
import { Theme } from '../utils/theme'

function UpdateArticleScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'UpdateArticle'>) {
  const {
    articleId,
    title: orgTitle,
    isAutoTitle: orgAutoTitle,
    latestContent,
    clusterId,
  } = route.params
  const { t } = useTranslation('articles')

  const [title, setTitle] = useState(orgTitle)
  const [isAutoTitle, setIsAutoTitle] = useState(orgAutoTitle)
  const [titleHeight, setTitleHeight] = useState(30)

  useEffect(() => {
    // altho this should never happen
    if (!articleId) {
      navigation.pop()
    }
  })
  const [doUpdateArticle, { loading }] = useUpdateArticleMutation()
  const [doDeleteArticle, { loading: deleting }] = useDeleteArticleMutation({
    update: (cache, result) => {
      const field = `clusterForPerspective(${JSON.stringify({
        id: clusterId,
      })})`
      cache.modify({
        id: 'Cluster:' + clusterId,
        fields: {
          articles: (existingRefs, { readField }) => {
            return existingRefs.filter(
              (ref: any) => articleId !== readField('id', ref)
            )
          },
        },
      })
    },
  })

  const handleUpdateArticle = async () => {
    if (!title && !isAutoTitle) {
      return Toast.show(t("The title can't be empty"), MessageType.warn)
    }

    let _updatedTitle = title
    if (isAutoTitle) {
      let text = latestContent.replace(/<[^>]+>/g, '')
      text = text.replace(/\&nbsp;/g, ' ')
      _updatedTitle = text.slice(0, 30)
    }
    console.log(_updatedTitle, '_updatedTitle  here')

    const input: UpdateArticleInput = {
      id: articleId,
      title: _updatedTitle,
      isAutoTitle,
    }

    try {
      const result = await doUpdateArticle({ variables: { input } })
      Toast.show(t('Updated successfully'), MessageType.info)
      navigation.pop()
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
    }
  }
  const handleDeleteArticle = async () => {
    try {
      const result = await doDeleteArticle({
        variables: { id: articleId },
      })
      Toast.show(t('Article deleted'))
      navigation.pop()
      navigation.pop()
    } catch (e) {
      Toast.show((e as Error).message), MessageType.error
    }
  }

  function toHomepage() {
    navigation.navigate('ClusterList', {})
  }

  const styles = StyleSheet.create({
    container: {
      paddingTop: 50,
      paddingBottom: 50,
      flexDirection: 'column',
    },
    title: {
      height: 20,
      justifyContent: 'flex-start',
      backgroundColor: !isAutoTitle ? 'white' : '#E3E6E8',
      marginBottom: 10,
      minHeight: 30,
      paddingHorizontal: 15,
    },
    button: {
      marginHorizontal: 10,
      marginTop: 10,
      flexGrow: 1,
    },
    containerValidation: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  })
  return (
    <ScrollView>
      <View style={styles.container}>
        <View
          style={{
            // backgroundColor: '#f80',
            flex: 1,
            // width: '100%',
            flexDirection: 'row',
            // justifyContent: 'center',
            marginHorizontal: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexGrow: 1,
              marginRight: 5,
            }}
          >
            <TextInput
              editable={!isAutoTitle}
              // onContentSizeChange={evt => setTitleHeight(Math.ceil(evt.nativeEvent.contentSize.height + 13))}
              style={[styles.title, { height: titleHeight }]}
              underlineColorAndroid="transparent"
              placeholder={isAutoTitle ? t('Automatic title') : t('Your title')}
              placeholderTextColor="grey"
              multiline={true}
              onChangeText={(text) => setTitle(text)}
              value={title}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              flex: 0,
              flexBasis: 100,
              alignItems: 'center',
            }}
          >
            <Text style={{ fontSize: 12, marginRight: 5 }}>
              {t('Auto Title')}:{' '}
            </Text>
            <Checkbox
              disabled={false}
              value={isAutoTitle}
              onValueChange={(newValue) => setIsAutoTitle(newValue)}
            />
          </View>
        </View>

        <View style={[styles.containerValidation, { marginTop: 50 }]}>
          <MelddButton
            onPress={toHomepage}
            color="grey"
            title={t('Cancel')}
            textColor="white"
            fontSize={15}
            style={styles.button}
            isDisabled={loading}
          />

          <MelddButton
            onPress={() => handleUpdateArticle()}
            color="#084678"
            textColor="white"
            title={t('Update')}
            fontSize={15}
            isDisabled={loading}
            isLoading={loading}
            style={styles.button}
          />
        </View>
        <View style={styles.containerValidation}>
          <MelddButton
            onPress={handleDeleteArticle}
            color={Theme.color.warning.main}
            textColor="white"
            title={t('Delete')}
            fontSize={15}
            isDisabled={loading || deleting}
            style={styles.button}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export default UpdateArticleScreen
