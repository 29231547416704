import * as React from 'react'
import { ScrollView } from 'react-native'
import { Card, Icon, List, Text } from 'react-native-paper'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { useTranslation } from 'react-i18next'
import { TimeLineIcon } from '../../utils/meldd-icons'
import { UserLink } from '../../screens/ClusterCards/components/ClickableClusterMember'
import Row from '../containers/Row'
import { TouchableIconWithToolTip } from '../../utils/meldd-tooltip'
import { useSocketTimeline } from '../../socket/socket.hooks'
import { AppNavigation } from '../../navigation/types'
import { useNavigation } from '@react-navigation/native'
import { navigateToTimelinePayload } from '../../utils/target'

export default function EagleView({
  targetId,
  open,
  onClose,
}: {
  targetId: string
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslation(['eagle-view', 'common'])
  const styles = useStyle()
  const scrollViewRef = React.useRef<ScrollView>(null)
  const navigation = useNavigation<AppNavigation>()
  const timeline = useSocketTimeline(targetId)

  React.useEffect(() => {
    if (!open) return
    setTimeout(() => {
      scrollViewRef.current?.scrollToEnd({ animated: false })
    }, 100)
  }, [open, timeline.length])

  if (!open) return null
  const opacity = (index: number) => {
    const opacity = [1, 0.7, 0.5, 0.35]
    const reverseIndex = timeline.length - 1 - index
    return opacity[reverseIndex] || 0.2
  }
  return (
    <Card style={styles.card}>
      <Card.Content>
        <Row justifyContent="flex-end">
          <TouchableIconWithToolTip
            tooltip={t('close')}
            icon={<Icon source="close" size={16} />}
            onPress={onClose}
            mode="text"
          />
        </Row>
        <ScrollView
          style={styles.scrollView}
          ref={scrollViewRef}
          contentContainerStyle={styles.scrollContent}
          horizontal={false}
        >
          {timeline.map((item, index) => (
            <List.Item
              key={index}
              title={
                <Text variant="labelSmall" style={{ opacity: opacity(index) }}>
                  {t(`action.${item.action}`)}
                </Text>
              }
              left={() => <TimeLineIcon item={item} />}
              right={() => (
                <UserLink
                  user={item.user}
                  style={{ opacity: opacity(index) }}
                />
              )}
              description={
                <Text
                  variant="bodySmall"
                  style={{ opacity: opacity(index), fontStyle: 'italic' }}
                >
                  {item.payload.title}
                </Text>
              }
              style={styles.listItem}
              onPress={() => {
                navigateToTimelinePayload({
                  timeline: item,
                  navigation,
                })
              }}
            />
          ))}
        </ScrollView>
      </Card.Content>
    </Card>
  )
}

const useStyle = createStyle(() => ({
  card: {
    backgroundColor: 'transparent ', // instead of white with opacity
    margin: 4,
    maxHeight: '80%',
  },
  scrollView: {
    height: 150,
  },
  scrollContent: {
    flexGrow: 1,
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
    borderBottomWidth: 0.5,
    borderBottomColor: '#e0e0e0',
  },
}))
