import { useMemo, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useTheme, TextInput, Menu, PaperProvider } from 'react-native-paper'
import { Controller } from 'react-hook-form'

export const MelddPicker = ({
  form,
  name,
  label,
  options,
  rules,
}: {
  form: any
  label: string
  name: string
  options: Array<{ label: string; value: string }>
  rules: any
}) => {
  const styles = useStyle()
  const [visible, setVisible] = useState(false)

  console.log({ options, value: form.getValues(name) })
  return (
    <Controller
      control={form.control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <View style={styles.pickerContainer}>
          <PaperProvider>
            <Menu
              visible={visible}
              onDismiss={() => setVisible(false)}
              style={styles.picker}
              anchor={
                <Pressable onPress={() => setVisible(true)}>
                  <TextInput
                    label={label}
                    value={
                      options.find((option) => option.value === value)?.label ||
                      ''
                    }
                    editable={false}
                    style={{ backgroundColor: 'rgb(226, 225, 236) ' }}
                    right={
                      <TextInput.Icon
                        icon="menu-down"
                        onPress={() => setVisible(true)}
                      />
                    }
                  />
                </Pressable>
              }>
              {options.map(({ label, value: optionValue }, index) => (
                <Menu.Item
                  key={index}
                  onPress={() => {
                    onChange(optionValue)
                    setVisible(false)
                  }}
                  style={{ width: '100%', maxWidth: '100%' }}
                  title={label}
                />
              ))}
            </Menu>
          </PaperProvider>
        </View>
      )}
    />
  )
}

const useStyle = () => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        pickerContainer: {
          position: 'relative',
          width: '100%',
          zIndex: 1000,
        },
        picker: {
          position: 'absolute',
          top: 60,
          left: 0,
          right: 0,
          zIndex: 1000,
        },
      }),
    [theme]
  )
}
