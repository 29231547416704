import * as React from 'react'
import { Appbar, IconButton, Text } from 'react-native-paper'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigation, AppNavigatorParams } from '../navigation/types'
import { Button, View } from 'react-native'
import NodeSelector from './NodeSelector'
import { useNode } from '../contexts/CurrentNodeProvider'
import NavigationBarMenu from './NavigationBarMenu'
import { useTheme } from '../hooks/use-theme'
import { DecisionsButton } from '../screens/Contributions/components/DecisionsButton'
import { CoRankButton } from './CoRankButton'
import { MelddToolTip } from '../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../auth/auth-context'
import AnonymousBarMenu from './AnonumusBarMenu'
import { useNavigation } from '@react-navigation/native'
import { getReturnPath } from '../screens/Login/login-required.modal'

export interface NodeNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

const LoginButton = () => {
  const navigation = useNavigation<AppNavigation>()
  return (
    <View>
      <IconButton
        onPress={() =>
          navigation.navigate('LoginMobileNumber', {
            returnPath: getReturnPath(navigation),
          })
        }
        icon="login"
        size={24}
      />
    </View>
  )
}
export function NodeNavigationBar({ navigation }: NodeNavigationBarProps) {
  const theme = useTheme()
  const { nodes, setCurrentNode, currentNode } = useNode()
  const { t } = useTranslation('header')
  const { isAnonymous } = useAuthContext()
  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      <View
        style={{
          flex: 1,
          marginHorizontal: 12,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}>
        <View style={{ flex: 0, flexBasis: '100%', maxWidth: 300 }}>
          <NodeSelector
            selected={currentNode}
            nodes={nodes}
            onSelect={setCurrentNode}
          />
        </View>
        {currentNode && (
          <MelddToolTip title={t('info')}>
            <IconButton
              disabled={!currentNode}
              icon="information-outline"
              onPress={() =>
                navigation.push('NodeStats', { node: currentNode })
              }
            />
          </MelddToolTip>
        )}
      </View>
      {isAnonymous ? (
        <>
          <LoginButton />
          <AnonymousBarMenu navigation={navigation} />
        </>
      ) : (
        <>
          <CoRankButton />
          <DecisionsButton />
          <NavigationBarMenu navigation={navigation} />
        </>
      )}
    </Appbar.Header>
  )
}
