import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Text } from 'react-native-paper'
import HTML from 'react-native-render-html'

interface ValidationMessageProps {
  title: string
  html: string
}

export const HTMLErrorBox: React.FC<ValidationMessageProps> = ({
  title,
  html,
}) => {
  const { t } = useTranslation('contributions')
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(!!html)
  }, [html])

  if (!isVisible) {
    return null
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.errorMessage}>{title}</Text>
        <HTML source={{ html }} />
      </View>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={() => setIsVisible(false)}>
        <Text style={styles.closeButtonText}>{t('close')}</Text>
      </TouchableOpacity>
    </View>
  )
}

/**
 * TODO: We shall use standard color library
 * But GPT chosed those ones and looks really nice in deed
 */
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
    borderWidth: 1,
    borderRadius: 4,
    padding: 15,
    marginVertical: 10,
  },
  content: {
    marginBottom: 10,
  },
  errorMessage: {
    color: '#721c24',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: '#721c24',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 4,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 14,
  },
})
