import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    width: '100%',
    paddingHorizontal: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 150,
    //  paddingBottom: 200,
  },

  topic: {
    height: 20,
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    marginBottom: 10,
    minHeight: 30,
    paddingHorizontal: 10,
  },
  button: {
    marginHorizontal: 10,
    marginTop: 10,
  },
  containerButtons: {
    marginTop: 20,
    //height: 350,
    flexDirection: 'row',
    justifyContent: 'center',
    // marginBottom: 500,
  },
  checkbox: {
    alignSelf: 'center',
  },
})
