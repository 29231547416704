import React, { useEffect, useMemo, useState } from 'react'
import {
  Keyboard,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { AntDesign, MaterialIcons } from '@expo/vector-icons'
import Toast from 'react-native-root-toast'
import {
  CreateNodeMemberInput,
  SearchUserQuery,
  useCreateNodeMemberMutation,
  useNodeMembersByNodeIdQuery,
  useRemoveNodeMemberMutation,
  useSearchUserLazyQuery,
} from '../../../generated/graphql'
import { sortBy } from 'lodash'
import { Unpacked } from '../../../utils/types'

const styles = StyleSheet.create({
  container: {},
  list: {},
  containerMember: {
    flexDirection: 'row',
    marginVertical: 15,
  },

  title: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    fontSize: 15,
  },
  input: {
    backgroundColor: 'white',
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderRadius: 3,
    marginVertical: 3,
    width: '65%',
  },
  searchList: {
    // backgroundColor: 'white',
    borderRadius: 5,
    height: 80,

    width: '100%',
  },
  searchComponent: {
    position: 'relative',
  },
  searchListItem: {
    width: '65%',
    backgroundColor: 'white',

    borderTopColor: '#ccc',
    borderTopWidth: 1,
  },
})

interface Props {
  nodeId: string
}
type ListUser = Unpacked<SearchUserQuery['usersBySearchUsername']>
const ModeMemberList = (props: Props) => {
  const { nodeId } = props
  const [search, setSearch] = useState<string>('')
  const [searchResult, setSearchResult] = useState<ListUser[]>([])
  const { loading, data, refetch } = useNodeMembersByNodeIdQuery({
    variables: { nodeId },
  })
  const nodeMembers = useMemo(
    () => sortBy(data?.nodeMembersByNodeId || [], ['user.username']),
    [data]
  )
  const [searchByUsername, { loading: loadingSearch, data: dataSearch }] =
    useSearchUserLazyQuery()
  useEffect(() => {
    refetch({ nodeId: nodeId })
  }, [])
  const [doAddMember] = useCreateNodeMemberMutation({
    onCompleted: () => refetch(),
  })
  const [doRemoveMember] = useRemoveNodeMemberMutation({
    onCompleted: () => refetch(),
  })

  async function addNewMember(userId: string) {
    const input: CreateNodeMemberInput = {
      userId,
      nodeId,
    }
    await doAddMember({
      variables: { input },
    }).catch(({ message, error }) => {
      Keyboard.dismiss()
      setSearch('')
      setSearchResult([])
      Toast.show(message)
    })
  }

  async function deleteMember(id: string) {
    await doRemoveMember({
      variables: { id },
    }).catch(({ message, error }) => {
      Toast.show(message)
    })
  }

  function renderSearchResults() {
    if (!searchResult) {
      return <></>
    }
    return (
      <ScrollView style={styles.searchList} nestedScrollEnabled={true}>
        {searchResult.map((item, index) => {
          return (
            <TouchableWithoutFeedback
              onPress={() => addNewMember(item.id)}
              key={index}
            >
              <View style={styles.searchListItem} key={index}>
                <Text style={[{ padding: 10 }]}>{item?.username} </Text>
              </View>
            </TouchableWithoutFeedback>
          )
        })}
      </ScrollView>
    )
  }

  function renderHeaderSearchBar() {
    return (
      <View style={[{ flexDirection: 'row' }]}>
        <TextInput
          value={search}
          style={styles.input}
          onChangeText={(text) => handleSearch(text)}
          placeholder="Search by username..."
          keyboardType="default"
        />

        {searchResult && (
          <View
            style={{
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <TouchableOpacity onPress={() => setSearchResult([])}>
              <AntDesign size={25} color="gray" name="closesquare"></AntDesign>
            </TouchableOpacity>
          </View>
        )}
      </View>
    )
  }

  async function handleSearch(input: string) {
    setSearch(input)
    const query = await searchByUsername({
      variables: { username: input },
    })
    if (query.data) {
      const result: ListUser[] = query.data.usersBySearchUsername
      setSearchResult(result)
    }
  }

  return (
    <View style={styles.container}>
      <View style={{ paddingVertical: 10 }}>
        <Text style={{ paddingBottom: 5 }}>
          Add a new member to the cluster:
        </Text>
        <View style={styles.searchComponent}>
          {renderHeaderSearchBar()}
          {renderSearchResults()}
        </View>
      </View>

      <Text style={styles.title}>Node's Members</Text>
      {loading ? (
        <Text>Loading</Text>
      ) : (
        <ScrollView>
          {nodeMembers.map((item, index) => {
            const user = item.user
            return (
              <View key={index} style={styles.containerMember}>
                <Text style={{ fontWeight: 'bold' }}> {user.username} </Text>
                <TouchableOpacity
                  style={{ paddingLeft: 25 }}
                  onPress={() => deleteMember(item.id)}
                >
                  <View style={{ width: 30 }}>
                    <MaterialIcons name="delete" size={20} />
                  </View>
                </TouchableOpacity>
              </View>
            )
          })}
        </ScrollView>
      )}
    </View>
  )
}

export default ModeMemberList
