import * as React from 'react'
import { useTheme } from '../../hooks/use-theme'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { View, StyleSheet, Text, ViewStyle } from 'react-native'
import { DataTable } from 'react-native-paper'
import Animated, { Layout } from 'react-native-reanimated'
import { StyleProp } from 'react-native'

interface TitledTableProps {
  children: React.ReactNode
  header: React.ReactNode
  headerStyle?: StyleProp<ViewStyle>
  title?: string
}

export function TitledTable(props: TitledTableProps) {
  const theme = useTheme()
  const globalStyles = useGlobalStyles()
  const styles = StyleSheet.create({
    tableCard: {
      borderColor: theme.colors.surfaceVariant,
      borderWidth: 1,
      borderRadius: theme.roundness,
      borderBottomColor: 'transparent',
      overflow: 'hidden',
    },
    title: { paddingVertical: theme.spacing(4) },
    header: { backgroundColor: theme.colors.surfaceVariant },
  })

  return (
    <Animated.View style={styles.tableCard} layout={Layout}>
      <DataTable>
        {props.title && (
          <DataTable.Header style={styles.title}>
            <Text>{props.title}</Text>
          </DataTable.Header>
        )}
        <DataTable.Header style={[styles.header, props.headerStyle]}>
          {props.header}
        </DataTable.Header>

        {props.children}
      </DataTable>
    </Animated.View>
  )
}
