import * as React from 'react'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AvailableNodesQuery,
  useAvailableNodesLazyQuery,
} from '../generated/graphql'
import { useAuthContext } from '../auth/auth-context'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Unpacked } from '../utils/types'
import { MdNode } from '../screens/ListNodes/types'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import Toast from 'react-native-root-toast'
import { MessageType } from '../utils/message-type'
import { orderBy, sortBy } from 'lodash'
import { l } from 'vitest/dist/index-5aad25c1'

interface CurrentNodeProviderProps {
  children: ReactNode
}

interface CurrentNodeProviderShape {
  currentNode?: NdNode
  setCurrentNode: (node?: NdNode) => void
  setNodeId: (nodeId?: string) => void
  nodes: NdNode[]
  refetch: (nodeId?: string) => Promise<MdNode[]>
  loading: boolean
  error?: ApolloError
}

const CURRENT_NODE_KEY = 'currentNode'

const CurrentNodeContext = createContext<CurrentNodeProviderShape>({
  currentNode: undefined,
  setCurrentNode: () => {},
  setNodeId: () => {},
  nodes: [],
  refetch: async (nodeId?: string) => [],
  loading: false,
  error: undefined,
})

type NdNode = Unpacked<AvailableNodesQuery['availableNodes']>

export function CurrentNodeProvider(props: CurrentNodeProviderProps) {
  const { userId, isAnonymous } = useAuthContext()
  const [currentNode, _setCurrentNode] = useState<MdNode>()
  const [nodes, setNodes] = useState<MdNode[]>([])
  const [loading, setLoading] = useState(true)
  const nextNodeRef = useRef<string>()
  const [query, { error }] = useAvailableNodesLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const refetch = useCallback(
    async (nodeId?: string) => {
      const lastNode = await AsyncStorage.getItem(
        `${CURRENT_NODE_KEY}:${userId}`
      )
      // If the node is not found, we fallback to the next condition
      const selectNode = (nodes: NdNode[]) => {
        // Abel: Is that ok? They search for a node and if not found, they deliver other one?
        if (nodeId) {
          const node = nodes.find((node) => node.id === nodeId)
          if (node) {
            return node
          }
        }
        if (nextNodeRef.current) {
          const node = nodes.find((node) => node.id == nextNodeRef.current)
          if (node) {
            return node
          }
        }

        if (lastNode) {
          const node = nodes.find((node) => node.id == lastNode)
          if (node) {
            return node
          }
        }
        return undefined
      }

      try {
        setLoading(true)
        const result = await query()
        const nodesResult = orderBy(
          result.data?.availableNodes || [],
          ['isPrivate', 'name'],
          ['asc', 'asc']
        )
        setNodes(nodesResult)
        _setCurrentNode(selectNode(nodesResult))
        setLoading(false)
        return nodes
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        setLoading(false)
        return []
      }
    },
    [query, userId]
  )

  useEffect(() => {
    refetch()
  }, [userId])

  const setCurrentNode = useCallback((node?: NdNode) => {
    if (node) {
      AsyncStorage.setItem(`${CURRENT_NODE_KEY}:${userId}`, node?.id)
    } else {
      AsyncStorage.removeItem(`${CURRENT_NODE_KEY}:${userId}`)
    }
    _setCurrentNode(node)
  }, [])
  const setNodeId = useCallback(
    (nodeId?: string) => {
      if (loading) {
        nextNodeRef.current = nodeId
        return
      }
      const toSelect = nodes.find((n) => n.id == nodeId)
      if (toSelect) {
        setCurrentNode(toSelect)
      }
    },
    [loading, nodes]
  )

  const value: CurrentNodeProviderShape = useMemo(
    () => ({
      currentNode,
      setCurrentNode,
      nodes,
      refetch,
      loading,
      error,
      setNodeId,
    }),
    [currentNode, setCurrentNode, nodes, loading, error]
  )

  return <CurrentNodeContext.Provider value={value} {...props} />
}

export const useNode = (nodeId?: string) => {
  const context = useContext(CurrentNodeContext)

  // If a param is specify then we shall tr tlo load this param.
  if (nodeId && context.currentNode?.id != nodeId) {
    context.setNodeId(nodeId)
  }
  // Typical Case a new user enters the website directly.
  if (!nodeId && !context.currentNode && context.nodes.length > 0) {
    context.setNodeId(context.nodes[0].id)
  }
  return context
}
