import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Text } from 'react-native'
import { Chip, Menu, Searchbar } from 'react-native-paper'
import { useTheme } from '../hooks/use-theme'
import { MdNode } from '../screens/ListNodes/types'
import { IconNode } from '../utils/meldd-icons'

interface NodeSelectorProps {
  selected?: MdNode
  nodes: MdNode[]
  onSelect: (node: MdNode) => void
}

export default function NodeSelector(props: NodeSelectorProps) {
  const windowWidth = Dimensions.get('window').width
  const theme = useTheme()
  const { t } = useTranslation('nodes')
  const [searchQuery, setSearchQuery] = useState('')
  const [visible, setVisible] = useState(false)
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const onSelection = useCallback(
    (node: MdNode) => {
      setVisible(false), props.onSelect(node)
    },
    [props.onSelect]
  )
  const menuItems = useMemo(
    () =>
      props.nodes
        .filter((node) =>
          node.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .map((node) => (
          <Menu.Item
            key={node.id}
            style={{ width: '100%', maxWidth: '100%' }}
            leadingIcon={IconNode}
            onPress={() => onSelection(node)}
            title={node.name}
          />
        )),
    [props.nodes, searchQuery]
  )

  return (
    <Menu
      visible={visible}
      anchorPosition={'bottom'}
      style={{
        flex: 1,
        marginTop: theme.spacing(1),
        width: windowWidth - 2 * theme.spacing(4),
      }}
      onDismiss={() => {
        // Only dismiss if search isn't focused
        if (!isSearchFocused) {
          setVisible(false)
        }
      }}
      anchor={
        <Chip
          icon={IconNode}
          mode="outlined"
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => setVisible((prev) => !prev)}
          onClose={() => setVisible((prev) => !prev)}
        >
          <Text>{props.selected?.name || ''}</Text>
        </Chip>
      }
    >
      <Searchbar
        placeholder={t('search-nodes')}
        onChangeText={setSearchQuery}
        value={searchQuery}
        rippleColor="transparent"
        onBlur={() => setIsSearchFocused(false)}
        onFocus={() => setIsSearchFocused(true)}
        onIconPress={() => setIsSearchFocused(true)}
        onClearIconPress={() => setIsSearchFocused(true)}
      />
      {menuItems}
    </Menu>
  )
}
