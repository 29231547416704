import React, { ReactNode } from 'react'
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ViewStyle,
  Insets,
} from 'react-native'
import Colors from '../constants/colors'
import PropTypes from 'prop-types'

interface Props {
  color: string
  title: string
  textColor: string
  textAlign?: string
  fontSize: number
  height: number
  isDisabled: boolean
  isDisabledBackground: string
  isLoading: boolean
  spinnerColor: string
  style?: ViewStyle | ViewStyle[]
  children?: ReactNode
  hitSlop?: null | Insets | number | undefined
  onPress: () => void
  onLongPress?: () => void
}
const MelddButton = (props: Props) => {
  const styles = StyleSheet.create({
    text: {
      color: props.textColor
        ? props.textColor
        : props.color
          ? 'black'
          : 'white',
      textAlign: props.textAlign || 'auto',
      fontSize: props.fontSize,
    },
    button: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 10,
      height: props.height,
      backgroundColor: props.isDisabled
        ? props.isDisabledBackground
        : props.color
          ? props.color
          : Colors.primary,
      borderRadius: 5,
      margin: 1,
    },
  })
  return (
    <Pressable
      hitSlop={props.hitSlop ? props.hitSlop : 5}
      disabled={props.isDisabled || props.isLoading}
      onPress={props.onPress}
      onLongPress={props.onLongPress}
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.5 : 1,
        },

        styles.button,
        props.style,
      ]}
    >
      {!props.isLoading ? (
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.text}> {props.title}</Text>
          <Text>{props.children}</Text>
        </View>
      ) : (
        <ActivityIndicator size="small" color={props.spinnerColor} />
      )}
    </Pressable>
  )
}

export default MelddButton

MelddButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hitSlop: PropTypes.number,
  height: PropTypes.number,
  spinnerColor: PropTypes.string,
  fontSize: PropTypes.number,
  isDisabled: PropTypes.bool,
  isDisabledBackground: PropTypes.string,
  isLoading: PropTypes.bool,
}

MelddButton.defaultProps = {
  height: 40,
  isDisabled: false,
  isDisabledBackground: '#b7b7b7',
  fontSize: 18,
  isLoading: false,
  spinnerColor: 'white',
}
