import { Text, View } from 'react-native'
import ModeMemberList from './components/ModeMemberList'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'

function NodeSettingsScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'NodeSettings'>) {
  const { node } = route.params
  const { t } = useTranslation('nodes')

  return (
    /*     <ScrollView nestedScrollEnabled={true}> */
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
          marginBottom: 15,
        }}
      >
        <Text style={{ marginRight: 5 }}>{t('Node Settings for')}:</Text>
        <Text style={{ fontWeight: 'bold' }}>{node.name}</Text>
      </View>
      {node.isPrivate && <ModeMemberList nodeId={node.id} />}
    </View>
  )
}

export default NodeSettingsScreen
