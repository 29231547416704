import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { SwipeableCards } from '../../components/SwipeableCards'
import { StyleSheet, View } from 'react-native'
import { useNode } from '../../contexts/CurrentNodeProvider'
import { useAvailableClustersLazyQuery } from '../../generated/graphql'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { PerspectiveCard } from '../../components/PerspectiveCard/PerspectiveCard'
import { LevelIndicator } from '../../components/LevelIndicator'
import { useTheme } from '../../hooks/use-theme'
import { IconButton } from 'react-native-paper'
import { TargetType } from './types'

export function PerspectiveCardsScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'PerspectiveCards'>) {
  const {
    params: { articleId, clusterId },
  } = route
  const theme = useTheme()
  const { currentNode } = useNode()

  const styles = StyleSheet.create({
    levelIndicator: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(4),
    },
  })
  const [doLoadClusters, { data }] = useAvailableClustersLazyQuery()
  const cluster = useMemo(
    () => (data?.availableClusters || []).find((c) => c.id === clusterId),
    [data, clusterId]
  )
  const perspectives = useMemo(() => cluster?.articles || [], [data, clusterId])
  const perspectiveCards = useMemo(
    () =>
      perspectives.map((a) => (
        <PerspectiveCard
          key={a.id}
          cluster={cluster!}
          nodeId={currentNode!.id}
          perspective={a}
        />
      )),
    [data]
  )

  const selectedIndex = useMemo(
    () => perspectives.findIndex((a) => a.id === articleId),
    [data, clusterId]
  )

  useEffect(() => {
    if (currentNode) {
      doLoadClusters({
        variables: { nodeId: currentNode.id },
        fetchPolicy: 'cache-and-network',
      })
    }
  }, [currentNode])
  return (
    <View style={{ flex: 1, marginBottom: 20 }}>
      <View style={styles.levelIndicator}>
        <IconButton
          mode={'outlined'}
          icon="arrow-left"
          onPress={() => navigation.goBack()}
        />
        <LevelIndicator target={TargetType.Article} targetId={articleId} flat />
      </View>

      {perspectiveCards.length > 0 && (
        <SwipeableCards initialSelectedIndex={selectedIndex}>
          {perspectiveCards}
        </SwipeableCards>
      )}
    </View>
  )
}

export default PerspectiveCardsScreen
