import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { orderBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, RefreshControl, StyleSheet, View } from 'react-native'
import { Button, IconButton, Text } from 'react-native-paper'
import Animated from 'react-native-reanimated'
import { useAuthContext } from '../../auth/auth-context'
import Row from '../../components/containers/Row'
import { Copyright } from '../../components/Copyright'
import { LevelIndicator } from '../../components/LevelIndicator'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useNode } from '../../contexts/CurrentNodeProvider'
import {
  createStyle,
  useGlobalStyles,
} from '../../contexts/GlobalStylesProvider'
import {
  ContributionStatus,
  ContributionType,
  useAvailableClustersLazyQuery,
} from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigatorParams } from '../../navigation/types'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'
import {
  Icon3dContributions,
  Icon3dPerspective,
  Icon3dVoteCount,
  IconAdd,
  IconGoTo,
} from '../../utils/meldd-icons'
import { getReturnPath } from '../Login/login-required.modal'
import { TargetType } from '../PerspectiveCards/types'
import { TaskPendingIcon } from '../tasks/components/task-pending-icon'
import { MelddTaskTargetType } from '../tasks/task.types'
import { Cluster } from './types'
import EagleView from '../../components/eagle-view/eagle-view'

export function ClusterListScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterList'>) {
  const isFocused = useIsFocused()
  const { params } = route
  const { t } = useTranslation('clusters')
  const globalStyles = useGlobalStyles()
  const { authAction, isAnonymous } = useAuthContext()
  const {
    currentNode,
    loading: loadingNodes,
    setCurrentNode,
  } = useNode(params?.external ? params?.nodeId : undefined)
  const [eagleViewOpen, setEagleViewOpen] = useState(false)
  const styles = useStyle()
  const [doLoadClusters, { data, loading, error }] =
    useAvailableClustersLazyQuery()

  const refresh = useCallback(() => {
    if (currentNode) {
      try {
        doLoadClusters({
          variables: { nodeId: currentNode.id },
          fetchPolicy: 'cache-and-network',
        })
      } catch (e) {
        setCurrentNode(undefined)
      }
    }
  }, [currentNode])

  useSocketRoom(currentNode?.id).event(SocketEvents.Refresh, refresh)

  useEffect(() => {
    if (
      (currentNode && !params?.nodeId) ||
      (currentNode && !params.external && currentNode.id !== params.nodeId)
    ) {
      navigation.setParams({ nodeId: currentNode.id, external: undefined })
    } else if (params?.external) {
      navigation.setParams({ nodeId: params.nodeId, external: undefined })
    }
  }, [currentNode, params])
  useEffect(() => {
    if (isFocused && !loadingNodes && !currentNode) {
      if (params?.nodeId && isAnonymous) {
        navigation.navigate('LoginMobileNumber', {
          returnPath: getReturnPath(navigation),
        })
        return
      }
      navigation.navigate('ListNodes')
      setTimeout(() => {}, 0)
    } else if (isFocused && currentNode) {
      refresh()
    }
  }, [loadingNodes, currentNode, isFocused, refresh])

  const theme = useTheme()

  const clusters = useMemo(() => {
    const ordered = orderBy(
      data?.availableClusters || [],
      ['contentUpdatedAt'],
      ['desc']
    )
    return ordered.map((cluster: Cluster) => {
      const onPress = () =>
        navigation.push('ClusterCards', { clusterId: cluster.id })
      const voteCount = cluster.articleVotes.reduce((acc, vote) => acc + 1, 0)
      const contributionCount = cluster.articles.reduce(
        (acc, article) =>
          acc +
          article.contributions.filter(
            (c) =>
              c.contributionType != ContributionType.Reserved &&
              c.status != ContributionStatus.Censured &&
              c.status != ContributionStatus.Cancelled
          ).length,
        0
      )
      return (
        <Animated.View key={cluster.id}>
          <Pressable onPress={onPress} style={styles.container}>
            <View style={styles.info}>
              <Text numberOfLines={3} variant={'titleLarge'}>
                {cluster.topic}
              </Text>
              <View style={styles.infoIcons}>
                <View style={styles.icon}>
                  <Icon3dPerspective
                    size={24}
                    onPress={() =>
                      navigation.push('ClusterCards', { clusterId: cluster.id })
                    }
                    label={cluster.articles.length.toString()}
                  />
                </View>
                <View style={styles.icon}>
                  <Icon3dContributions
                    size={24}
                    label={contributionCount.toString()}
                    onPress={() =>
                      navigation.push('ClusterContributions', {
                        clusterId: cluster.id,
                      })
                    }
                  />
                </View>
                <View style={styles.icon}>
                  <Icon3dVoteCount size={24} label={voteCount.toString()} />
                </View>
              </View>
            </View>
            <IconButton icon={IconGoTo} onPress={onPress} />
          </Pressable>
        </Animated.View>
      )
    })
  }, [data])

  const totalArticles = useMemo(() => {
    return (
      data?.availableClusters?.reduce(
        (acc, cluster) => acc + cluster.articles.length,
        0
      ) || 0
    )
  }, [data])

  const totalVotes = useMemo(() => {
    return (
      data?.availableClusters?.reduce((acc, cluster) => {
        return acc + cluster.articleVotes.length // Count votes for each cluster
      }, 0) || 0
    )
  }, [data])

  useWechatShareInit(() => {
    if (!currentNode || !data) return null
    return {
      title: currentNode.name,
      description: `${data.availableClusters.length} cluster(s), with ${totalArticles} perspective(s), and ${totalVotes} vote(s)`,
    }
  }, [currentNode, data, totalArticles, totalVotes])

  return (
    <View style={[globalStyles.pageContainer]}>
      <Row justifyContent={'space-between'} alignItems={'center'}>
        <Button
          disabled={!currentNode}
          icon={IconAdd}
          mode="elevated"
          onPress={() =>
            authAction(navigation, () =>
              navigation.push('CreateCluster', {
                nodeId: currentNode!.id,
                nodeName: currentNode!.name,
              })
            )
          }
        >
          {t('Create cluster')}
        </Button>
        <Row alignItems={'center'} columnGap={2}>
          <View style={styles.levelIndicator}>
            <LevelIndicator
              target={TargetType.Node}
              targetId={currentNode?.id || ''}
              flat={true}
              onEagleViewClick={() => setEagleViewOpen(!eagleViewOpen)}
              eagleViewOpen={eagleViewOpen}
            />
          </View>
          <TaskPendingIcon
            targetId={currentNode?.id || ''}
            targetType={MelddTaskTargetType.NODE_TASK}
            size={22}
          />
        </Row>
      </Row>

      <ScreenWrapper
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={refresh} />
        }
      >
        {clusters}
        <View style={{ height: theme.spacing(32) }}>{/* spacer */}</View>
      </ScreenWrapper>
      <EagleView
        targetId={currentNode?.id || ''}
        open={eagleViewOpen}
        onClose={() => setEagleViewOpen(false)}
      />
      {currentNode && !eagleViewOpen && (
        <MButton
          targetId={currentNode.id}
          targetType={MelddTaskTargetType.NODE_TASK}
          preview={currentNode.name}
        />
      )}
      <Copyright />
    </View>
  )
}
export default ClusterListScreen

const useStyle = createStyle(({ theme }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingRight: 0,
    borderBottomColor: theme.colors.surfaceVariant,
    borderBottomWidth: 1,
  },
  levelIndicator: {
    // width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  info: {
    rowGap: theme.spacing(2),
    flex: 1,
  },
  topic: {},
  infoIcons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  icon: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    width: theme.spacing(16),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(10),
  },
}))
